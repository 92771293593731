<template>
    <AModal
        :visible="visible"
        title="List Sub Segment"
        @cancel="handleModalCancel"
        :destroy-on-close="true"
        width="70%"
        :footer="null">

        <template v-if="hasRoles([ROLE_ADMIN_BK])">
            <AForm
                v-if="state.isForm"
                ref="formRef"
                class="myform"
                :model="form"
                :rules="state.rules"
                @finish="handleOk"
                :wrapper-col="{ span: 14 }"
                :scroll-to-first-error="true">
                
                <!-- sub name -->
                <AFormItem
                    label="Nama Sub Segment"
                    label-align="left"
                    :label-col="{ sm: { span: 4 } }"
                    :wrapper-col="{ sm: { span: 24 - 4 } }"
                    required
                    name="nama_subsegment"
                    has-feedback>
                    <AInput
                        placeholder="nama sub segment"
                        :disabled="form.readOnly"
                        v-model:value="form.nama_subsegment" />
                </AFormItem>

                <a-row class="form-row mt-2">
                    <ACol :sm="4"/>
                    <a-col :sm="20">
                        <ASpace>
                            <AButton
                                type="primary"
                                html-type="submit"
                                :loading="form.busy"
                                :disabled="form.busy">
                                {{ !form.id ? 'Simpan' : 'Perbarui' }}
                            </AButton>
                            <AButton
                                title="Batal"
                                @click="btnCancel">Batal</AButton>
                        </ASpace>
                    </a-col>
                </a-row>
            </AForm>
            <ARow
                v-else
                justify="space-between"
                align="middle"
                :gutter="[16, 16]">
                <ACol :flex="1">
                    <ARow :gutter="[8, 8]">
                        <ACol :xl="6" :md="8" :sm="12" :span="24">
                            <AInputSearch
                                allow-clear
                                placeholder="Cari..."
                                style="width: 100%"
                                v-model:value="state.params.q"/>
                        </ACol>
                        <ACol :xl="6" :md="8" :sm="12" :span="24">
                            <AButton
                                type="primary"
                                title="Cari"
                                @click="fetchDataList"
                                :loading="state.isFetching">
                                <template v-if="!state.isFetching">
                                    <span class="fa fa-search" aria-hidden="true" />
                                </template>
                                <template v-else>Memuat Data ...</template>
                            </AButton>
                        </ACol>
                    </ARow>
                </ACol>
                <ACol>
                    <ASpace>
                        <ATooltip
                            v-if="hasRoles([ROLE_ADMIN_BK])"
                            title="Tambahkan">
                            <AButton
                                @click="state.isForm = !state.isForm; form.reset();"
                                type="primary">
                                <i class="fa fa-plus" aria-hidden="true"></i>
                            </AButton>
                        </ATooltip>
                        <ATooltip title="Download Excel">
                            <DownloadExcel
                                :url="state.endpoint.current"
                                :params="state.params"
                                namefile="List-sub-segment"
                                @errors="errorMessage"/>
                        </ATooltip>
                    </ASpace>
                </ACol>
            </ARow>
        </template>
        
        <AAlert
            v-if="errorMessage"
            type="error"
            class="mt-2"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <div v-if="state.errors.length" class="mt-2">
            <AAlert
                v-for="(item, index) in state.errors"
                class="mb-2"
                type="error"
                :key="index"
                :message="item.message"
                banner
                closable
                @close="state.errors = []" />
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                :columns="state.columns"
                :data-source="state.data"
                size="small"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">

                <template #no="{ index }">
                    <span>
                        {{ (state.meta.page - 1) * state.meta.per_page + 1 + index }}
                    </span>
                </template>

                <template #action="{ record }">
                    <ASpace>
                        <ATooltip
                            title="Edit">
                            <AButton
                                class="button"
                                size="small"
                                @click="btnForm(record)">
                                <i class="fe fe-edit" />
                            </AButton>
                        </ATooltip>
                    </ASpace>
                </template>

            </MdTable>
        </div>
    </AModal>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import Form from 'vform'
import { pickBy } from 'lodash'
import {
    hasRoles,
    ROLE_ADMIN_BK,
} from '@/helpers'

export default defineComponent({
    components: {
        DownloadExcel,
    },
    props: {
        visible: [Boolean],
        item: {
            type: Object,
            default: () => ({
                id: null,
            }),
        },
    },
    emits: ['update:visible'],
    setup(props, { emit }) {
        const errorMessage = ref()
        const formRef = ref();

        const form = reactive(new Form({
            id: null,
            id_segment: props.item.id,
            nama_subsegment: null,
        }))

        const state = reactive({
            columns: [
                {
                    title: 'NO',
                    slots: { customRender: 'no' },
                },
                {
                    title: 'Nama Sub Segment',
                    dataIndex: 'nama_subsegment',
                },
                {
                    title: 'Action',
                    dataIndex: 'action',
                    slots: { customRender: 'action' },
                },
            ],
            endpoint: {
                current: `/api/sub-segments`,
                create: `/api/sub-segments`,
                update: `/api/sub-segments`, // /api/sub-segments/{id}
            },
            errors: [],
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            params: {
                q: '',
                id_segment: props.item.id,
                page: 1,
                "per-page": 10,
            },
            isForm: false,
            rules: {
                nama_subsegment: [
                    {
                        required: true,
                        message: "Nama tidak boleh kosong!",
                    },
                ],
            },
        })

        const handleModalCancel = () => {
            emit('update:visible', false)
            emit('update:item', null)
        }

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize

            fetchDataList()
        }

        const handleOk = async () => {
            // validation form
            await formRef.value.validate()
                .catch(() => { });

            let axiosForm = form.id
                ? form.put(`${state.endpoint.update}/${form.id}`)
                : form.post(state.endpoint.create)

            axiosForm.then(({ data }) => {
                    if (data === undefined) {
                        errorMessage.value = `Kode error 500, No response from server`
                        return
                    }

                    btnCancel()
                    fetchDataList()

                    message.success(form.id ? 'Berhasil memperbarui' : 'Berhasil menyimpan')
                })
                .catch(async error => {
                    errorMessage.value = null
                    if (error.response) {
                        const { status, statusText } = error.response
                        const message = error.response.data.msg
                        errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
                    }
                })
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true

            apiClient
                .get(state.endpoint.current, {
                    params: pickBy(state.params),
                })
                .then(({ data }) => {
                    const { items, _meta } = data

                    state.data = items
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        // handle form
        const btnForm = (item = null, readOnly = false) => {
            state.isForm = true
            form.id = item.id
            form.nama_subsegment = item.nama_subsegment
        }

        const btnCancel = () => {
            form.reset()
            formRef.value.resetFields()
            state.isForm = false
            errorMessage.value = null
            state.errors = []
        }

        onMounted(() => {
            fetchDataList()
        })

        return {
            fetchDataList,
            state,
            formRef,
            form,
            handleModalCancel,
            handleTableChange,
            handleOk,
            errorMessage,
            btnForm,
            btnCancel,
            // only variable role
            hasRoles,
            ROLE_ADMIN_BK,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/css/form.scss';
</style>
